<template>
  <div id="prices-management">
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <app-data-table
        ref="pricesManagementTable"
        table-name="prices-management"
        :fields="priceManagementsFields"
        :items="pricesManagement"
        :busy="pricesManagementLoading"
        :page="page"
        :per-page="numberOfItemsPerPage"
        :sort-by.sync="sort.sortBy"
        :sort-desc.sync="sort.sortDesc"
        manual-pagination
        :small="$store.getters['app/mdAndDown']"
        :top-table="{
          showSearch: $can('PRICE_MANAGEMENT_VIEW'),
          showAdd: $can('PRICE_MANAGEMENT_ADD'),
          disabled: pricesManagementLoading,
          searchFilterOptions,
          searchText: name,
        }"
        :actions-table="{
          showDuplicate: $can('PRICE_MANAGEMENT_ADD'),
          showEdit: $can('PRICE_MANAGEMENT_EDIT'),
          showDelete: $can('PRICE_MANAGEMENT_DELETE'),
        }"
        :bottom-table="{ totalCount: pagination.totalItems }"
        @table-select-per-page=";(numberOfItemsPerPage = $event), search()"
        @table-search-text="(name = $event), search()"
        @table-add="$router.push({ name: 'priceManagementAdd' })"
        @duplicate="$router.push({ name: 'priceManagementDuplicate', params: { id: $event.item.id } })"
        @edit="$router.push({ name: 'priceManagementEdit', params: { id: $event.item.id } })"
        @delete="deletePriceManagement($event.item)"
        @pagination="(page = $event), search()"
      >
        <!-- CELL: Name -->
        <template #cell(name)="data">
          <b-link :to="{ name: 'priceManagementEdit', params: { id: data.item.id } }" class="font-weight-bold d-block text-nowrap">
            {{ data.item.name }}
          </b-link>
        </template>

        <!-- CELL: Description -->
        <template #cell(description)="data">
          {{ data.item.description }}
        </template>

        <!--CELL: Status-->
        <template #cell(status)="data">
          {{ data.item.status|enumTranslate('price_management_status') }}
        </template>
      </app-data-table>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import { deletePriceManagementRequest } from '@/request/globalApi/requests/priceManagementRequests'
import AppDataTable from '@/components/AppDataTable.vue'

export default {
  name: 'PricesManagement',

  components: {
    AppDataTable,
  },

  data() {
    return {
      searchFilterOptions: [
        { text: this.$t('price_management.name'), value: 'name' },
      ],
      priceManagementsFields: [
        { key: 'name', label: this.$t('price_management.name'), sortable: true },
        { key: 'description', label: this.$t('price_management.group.description'), thClass: 'w-100', thStyle: { 'min-width': '300px' }, sortable: true },
        { key: 'status', label: this.$t('price_management.status') },
      ],
      sort: {
        sortBy: null,
        sortDesc: null,
      },
    }
  },

  computed: {
    ...mapState('priceManagement', ['pricesManagementLoading', 'pricesManagement', 'pagination', 'queryParams', 'sorting']),

    ...mapFields('priceManagement', ['queryParams.page', 'queryParams.numberOfItemsPerPage', 'queryParams.priceManagementStatus', 'search.name']),
  },

  watch: {
    queryParams() {
      this.fetchPriceManagements(true)
    },

    sort: {
      handler(value) {
        this.setSort(value)
      },
      deep: true,
    },
  },

  mounted() {
    if (this.$route.params.refresh) {
      this.fetchPriceManagements(this.$route.params.refresh)
    } else {
      this.fetchPriceManagements()
    }
  },

  beforeRouteLeave(to, from, next) {
    if (to.matched[0].path !== '/price-management') {
      this.clearPriceManagementsShowed()
    }
    next()
  },

  methods: {
    ...mapActions('priceManagement', ['fetchPriceManagements', 'clearPriceManagementsShowed', 'setSort']),

    deletePriceManagement(priceManagement) {
      deletePriceManagementRequest(priceManagement.id, priceManagement.name).then(response => {
        response && this.fetchPriceManagements(true)
      })
    },

    search() {
      this.fetchPriceManagements(true)
    },
  },
}
</script>
