var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"prices-management"}},[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('app-data-table',{ref:"pricesManagementTable",attrs:{"table-name":"prices-management","fields":_vm.priceManagementsFields,"items":_vm.pricesManagement,"busy":_vm.pricesManagementLoading,"page":_vm.page,"per-page":_vm.numberOfItemsPerPage,"sort-by":_vm.sort.sortBy,"sort-desc":_vm.sort.sortDesc,"manual-pagination":"","small":_vm.$store.getters['app/mdAndDown'],"top-table":{
        showSearch: _vm.$can('PRICE_MANAGEMENT_VIEW'),
        showAdd: _vm.$can('PRICE_MANAGEMENT_ADD'),
        disabled: _vm.pricesManagementLoading,
        searchFilterOptions: _vm.searchFilterOptions,
        searchText: _vm.name,
      },"actions-table":{
        showDuplicate: _vm.$can('PRICE_MANAGEMENT_ADD'),
        showEdit: _vm.$can('PRICE_MANAGEMENT_EDIT'),
        showDelete: _vm.$can('PRICE_MANAGEMENT_DELETE'),
      },"bottom-table":{ totalCount: _vm.pagination.totalItems }},on:{"update:sortBy":function($event){return _vm.$set(_vm.sort, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sort, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sort, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sort, "sortDesc", $event)},"table-select-per-page":function($event){;(_vm.numberOfItemsPerPage = $event), _vm.search()},"table-search-text":function($event){(_vm.name = $event), _vm.search()},"table-add":function($event){return _vm.$router.push({ name: 'priceManagementAdd' })},"duplicate":function($event){return _vm.$router.push({ name: 'priceManagementDuplicate', params: { id: $event.item.id } })},"edit":function($event){return _vm.$router.push({ name: 'priceManagementEdit', params: { id: $event.item.id } })},"delete":function($event){return _vm.deletePriceManagement($event.item)},"pagination":function($event){(_vm.page = $event), _vm.search()}},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"to":{ name: 'priceManagementEdit', params: { id: data.item.id } }}},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}},{key:"cell(description)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.description)+" ")]}},{key:"cell(status)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("enumTranslate")(data.item.status,'price_management_status'))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }